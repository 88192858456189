import logo from '../assets/images/logo-white.png' ; 
import {motion} from 'framer-motion' ; 
import ReactPlayer from 'react-player';
import {ReactComponent as PhoneSVG} from '../assets/svg/phone-volume-light.svg' ; 
import {ReactComponent as EmailSVG} from '../assets/svg/envelope-light.svg' ;
import {ReactComponent as GlobeSVG} from '../assets/svg/globe-pointer-light.svg' ;
import {ReactComponent as PinSVG} from '../assets/svg/map-pin-sharp-light.svg' ;
import {useRef } from 'react';


export default function Main() {
    const playerRef = useRef<ReactPlayer>(null);

    const handleVideoEnd = () => {
        if (playerRef.current) {
          playerRef.current.seekTo(0, 'seconds');
          playerRef.current.props.onPlay?.();
        }
    };

    return (
        <div className = "app">
            <motion.div 
                initial={{opacity: 0, y: -100}}
                animate={{opacity: 1, y: 0, transition : {type : "linear", duration : 0.3}}}
                exit={{opacity: 1, y: 0}}
                className='app--introduction'
            >
                <motion.img 
                    src = {logo} 
                    alt = "logo" 
                />
                <h1>Où l'Histoire rencontre l'Avenir</h1>
                <p>Rejoins nous pour écrire le prochain chapitre</p>
            </motion.div>

            <motion.div 
                initial={{opacity: 0, y: 100}}
                animate={{opacity: 1, y: 0, transition : {type : "linear", duration : 0.3, delay : 0.6}}}
                exit={{opacity: 1, y: 0}}
                className='app--video'
            >
                <ReactPlayer 
                    width='calc(100%)'
                    height='100%'
                    ref={playerRef}
                    url={'https://jobdating.site-htpnext.com/videos/htp-next-video.mp4'} 
                    onEnded={handleVideoEnd}
                    controls={true} 
                    playing={true}
                    muted={true}

                />
            </motion.div>

            <motion.div 
                initial={{opacity: 0, y: 100}}
                animate={{opacity: 1, y: 0, transition : {type : "linear", duration : 0.3, delay : 0.3}}}
                exit={{opacity: 1, y: 0}}
                className="app--contact"
            >
                <div>
                    <h1>Ton avenir + notre équipe = succès garanti</h1>
                    <h2>Postule et faisons les comptes !</h2>
                    <div>
                        <div>
                            <div><PhoneSVG /></div>
                            <a href="tel:0262251041">02 62 25 10 41</a>
                        </div>

                        <div>
                            <div><EmailSVG /></div>
                            <a href="mailto:contact@cabinet-htp.fr">contact@cabinet-htp.fr</a>
                        </div>

                        <div>
                            <div><GlobeSVG /></div>
                            <a href="http://site-htpnext.com/" target='_blank' rel="noreferrer">www.htpnext.com</a>
                        </div>

                        <div>
                            <div><PinSVG/></div>
                            <a href='https://goo.gl/maps/dXu468a7zT7h4z6a7' target='_blank' rel="noreferrer">17 rue Joseph Hubert, <br/> 97410 Saint-Pierre</a>
                        </div>
                    </div>
                </div>
            </motion.div>

        </div>
    )
}