import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit' ; 
import account_reducer from './account/reducer.account';
import global_reducer from './global/reducer.global';

export default configureStore({
    reducer : {
        account : account_reducer, 
        global : global_reducer, 
        //devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    }, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})